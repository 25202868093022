<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import { getUser, updateProfile, changePassword } from '@/api/Users.js'
  import { User } from "@/models/User";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Perfil",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Perfil",
        items: [
          {
            text: "Home",
            href: "/",
            },
            {
              text: "Perfil",
              active: true,
            },
        ],
        forms: {
          user: new User(),
          password: {
            current_password: null,
            new_password: null
          }
        },
        ui: {
          forms: {
            user: {
              disabled: false
            },
            password: {
              disabled: false
            }
          }
        }
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        
        getUser().then(response => {
          vm.forms.user = new User(response)
        })
      },
      updateProfile(){
        let vm = this

        if(this.ui.forms.user.disabled === false){
          this.ui.forms.user.disabled = true

          this.$awn.async(
            updateProfile(vm.forms.user),
            function (response) {
              vm.$store.commit('auth/SET_CURRENT_USER', response)
              vm.$awn.success('Perfil atualizado com sucesso.')
              vm.ui.forms.user.disabled = false
            },
            function () {
              vm.ui.forms.user.disabled = false
              vm.$awn.warning('Ocorreu um erro ao efetuar a recuperação de senha. Favor verificar os dados inseridos e tentar novamente.')
            }
          )
        }
      },
      changePassword(){
        let vm = this

        if(this.ui.forms.password.disabled === false){
          this.ui.forms.password.disabled = true

          this.$awn.async(
            changePassword(vm.forms.password),
            function () {
              vm.ui.forms.password.disabled = false
              vm.$awn.success('Senha atualizada com sucesso.')
            },
            function (error) {
              vm.ui.forms.password.disabled = false
              vm.$awn.warning(error || 'Ocorreu um erro ao alterar a senha. Favor verificar os dados inseridos e tentar novamente.')
            }
          )
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">
              Editar Perfil
            </h4>
  
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form
                @submit.stop.prevent="handleSubmit(updateProfile)"
                class="form-horizontal"
              >
                <validation-provider
                  name="Name"
                  :rules="{ required: true }"
                  v-slot="profile"
                >
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Nome"
                    label-for="text"
                  >
                    <b-form-input
                      for="text"
                      v-model="forms.user.name"
                      id="input-user-name"
                      name="name"
                      :state="getValidationState(profile)"
                    />
                    <b-form-invalid-feedback>{{ profile.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
    
                <b-form-group
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="E-mail"
                  label-for="email"
                >
                  <b-form-input
                    for="email"
                    v-model="forms.user.email"
                    :disabled="true"
                  />
                </b-form-group>
    
                <b-button variant="primary" style="float: right" type="submit">
                  Salvar
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
  
      <div class="col-lg-5">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">
              Alterar Senha
            </h4>
  
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form
                @submit.stop.prevent="handleSubmit(changePassword)"
                class="form-horizontal"
              >
                <validation-provider
                  name="Senha atual"
                  :rules="{ required: true, min: 8 }"
                  v-slot="password"
                >
                  <b-form-group
                    label-cols-sm="3"
                    label-cols-lg="3"
                    label="Senha atual"
                    label-for="current_password"
                  >
                    <b-form-input
                      for="current_password"
                      type="password"
                      v-model="forms.password.current_password"
                      :state="getValidationState(password)"
                    />
                    
                    <b-form-invalid-feedback>{{ password.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
  
                <validation-provider
                  name="Nova senha"
                  :rules="{ required: true, min: 8 }"
                  v-slot="password"
                >
                  <b-form-group
                    label-cols-sm="3"
                    label-cols-lg="3"
                    label="Nova senha"
                    label-for="new_password"
                  >
                    <b-form-input
                      for="new_password"
                      type="password"
                      v-model="forms.password.new_password"
                      :state="getValidationState(password)"
                    />
      
                    <b-form-invalid-feedback>{{ password.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
      
                <b-button variant="primary" style="float: right" type="submit">
                  Salvar
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
