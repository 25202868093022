import { axios } from '@/plugins/axios';


export const getUsers = () => axios.get(`/users`).then(res => res.data);

export const getUser = () => axios.get(`/users/profile`).then(res => res.data);

export const createUser = (user) => {
  return axios
    .post(`/users`, user)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const updateProfile = (user) => {
  let obj = {
    name: user.name,
  };

  return axios
    .put(`/users/profile`, obj)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const changePassword = (passwords) => {
  return axios
    .put(`/users/password`, passwords)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const deleteUser = (user) => {
  return axios
    .delete(`/users/${user.id}`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};